import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import african from "../../../assets/img/banner/african.jpg";
import baby from "../../../assets/img/banner/baby.jpg";

import "./Banner.css";
import { Link } from "react-router-dom";
import { useGetBannerQuery } from "./bannerSlice";
import axios from "axios";
import { bannerDB } from "../../../rki-data/banner";
import { isMobile } from "react-device-detect";
import { base_url } from "../../../server";
import bann from "../../../assets/img/newban.png"
import bann1 from "../../../assets/img/newban1.jpg"
const bannerData = bannerDB;
function Banner() {
  const [data, setData] = useState([
    { _id: '1', image: { url: bann } },
    { _id: '2', image: { url: bann1 } },
  ]);
  const baseUrl = base_url();

  var settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const getData = async () => {
    try {
      const res = await axios.get(`${baseUrl}banner/public`, {
        withCredentials: true,
      });
      setData(res.data);
    } catch (error) {
      alert("Server Error BannerList");
    }
  };
  useEffect(() => {
    // getData();
  }, []);

  return (
    <>
      <section className="bannerSection">
        <Slider {...settings}>
          {data &&
            data.map((item) => {
              return (
                item?.image?.url && (
                  <Link to={item?.url || ""} key={item._id}>
                    <div
                      className="bannerItem"
                      style={!isMobile ? { height: "570px" } : {}}
                    >
                      <img
                        src={item?.image.url}
                      // className="aspect-ratio-img"
                      />
                    </div>
                  </Link>
                )
              );
            })}
        </Slider>
      </section>
    </>
  );
}

export default Banner;
