import React from "react";
import Breadcrumb from "../../shared/breadcrumb/Breadcrumb";
// import "lightbox.js-react/dist/index.css";
import { Helmet } from "react-helmet";
import { useGetGallaryListQuery } from "../products/productSlice";
import { ImgesData } from "../home/proImgs/ImgesData";

function PhotoGallery() {
  const { data, isLoading } = useGetGallaryListQuery()
  return (
    <>
      <Helmet>
        <title>Photo Gallery | Fertilizer Multi Vendor</title>
        <meta
          name="keyword"
          content="Fertilizer, Agricultural, Seeds, Machinery, Nutrition"
        />
        <meta
          name="description"
          content="Buy Agricultural Products and Machinery Online at ETG. We Offering broad range of Seeds, Plant Nutrition, Plant Protection and Agri Implements."
        />
      </Helmet>
      {/* <Breadcrumb title="Photo Gallery" /> */}
      <section className="photoGallery p-30">
        <div className="container">
          {isLoading && <div className="preloaderCount">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>}
          {/* Gallery */}
          <div className="row">
            <div className="col-lg-12 col-md-12 mb-4 mb-lg-0">
              <div className="pageTitle">
                <h4>Our Photo Gallery</h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi
                  excepturi fuga nemo vitae possimus repellat, praesentium
                  temporibus officiis, accusamus, labore nostrum aspernatur
                  dolore voluptatibus nesciunt placeat error odio atque ea.
                </p>
              </div>
            </div>
            {/* Gallery */}

            {/* <div className="row">


              {data && data.map((item, i) => {
                return <div className="col-lg-3" key={item._id} data-bs-toggle="modal" data-bs-target="#exampleModalPhoto">
                  <img
                    style={{ width: "100%" }}
                    src={ImgesData[i]?.url}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Fertilizer"
                  />
                </div>
              })}

            </div> */}
            {/* Gallery */}
          </div>

          {/* <div className="modal fade exampleModalPhoto" id="exampleModalPhoto" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <img src={ImgesData[0]?.url} />
                <div id="carouselExampleIndicators" className="carousel slide">
                  <div className="carousel-inner">
                    <div style={{borderTop:"2px solid gray"}} className="carousel-item active">
                      <img src={ImgesData[0]?.url} />
                    </div>
                    <div className="carousel-item">
                      <img src={ImgesData[0]?.url} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}

          <div
            data-elementor-type="wp-page"
            data-elementor-id={2007}
            className="elementor elementor-2007"
          >
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-0f6ef92 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="0f6ef92"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default" style={{display:"flex",justifyContent:"space-between" ,margin:"10px 0"}}>
                <div
                  className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-609bf02"
                  data-id="609bf02"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-fe958bf elementor-widget elementor-widget-heading"
                      data-id="fe958bf"
                      data-element_type="widget"
                      data-widget_type="heading.default"
                    >
                      <div className="elementor-widget-container">
                        <style
                          dangerouslySetInnerHTML={{
                            __html:
                              "/*! elementor - v3.20.0 - 13-03-2024 */\n.elementor-heading-title{padding:0;margin:0;line-height:1}.elementor-widget-heading .elementor-heading-title[class*=elementor-size-]>a{color:inherit;font-size:inherit;line-height:inherit}.elementor-widget-heading .elementor-heading-title.elementor-size-small{font-size:15px}.elementor-widget-heading .elementor-heading-title.elementor-size-medium{font-size:19px}.elementor-widget-heading .elementor-heading-title.elementor-size-large{font-size:29px}.elementor-widget-heading .elementor-heading-title.elementor-size-xl{font-size:39px}.elementor-widget-heading .elementor-heading-title.elementor-size-xxl{font-size:59px}"
                          }}
                        />
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-42b6928 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="42b6928"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default" style={{display:"flex",justifyContent:"space-between" ,margin:"10px 0"}}>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15556d4"
                  data-id="15556d4"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-f628aad elementor-widget elementor-widget-image"
                      data-id="f628aad"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/forest-honey/">
                          <img
                            fetchpriority="high"
                            decoding="async"
                            width={500}
                            height={400}
                            src="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-36.png"
                            className="elementor-animation-grow attachment-large size-large wp-image-5339"
                            alt=""
                            srcSet="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-36.png 500w, https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-36-300x240.png 300w"
                            sizes="(max-width: 500px) 100vw, 500px"
                          />{" "}
                        </a>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-7dedf26 premium-header-inline elementor-widget elementor-widget-premium-addon-dual-header"
                      data-id="7dedf26"
                      data-element_type="widget"
                      data-widget_type="premium-addon-dual-header.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/forest-honey/">
                          <h2 className="premium-dual-header-first-header ">
                            <span className="premium-dual-header-first-span">
                              Forest{" "}
                            </span>
                            <span className="premium-dual-header-second-header ">
                              Honey
                            </span>
                          </h2>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3703b93"
                  data-id="3703b93"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-57678d6 elementor-widget elementor-widget-image"
                      data-id="57678d6"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/honey-sweets/">
                          <img
                            decoding="async"
                            width={500}
                            height={400}
                            src="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-37.png"
                            className="elementor-animation-grow attachment-large size-large wp-image-5341"
                            alt=""
                            srcSet="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-37.png 500w, https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-37-300x240.png 300w"
                            sizes="(max-width: 500px) 100vw, 500px"
                          />{" "}
                        </a>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-b0203d8 premium-header-inline elementor-widget elementor-widget-premium-addon-dual-header"
                      data-id="b0203d8"
                      data-element_type="widget"
                      data-widget_type="premium-addon-dual-header.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/honey-sweets/">
                          <h2 className="premium-dual-header-first-header ">
                            <span className="premium-dual-header-first-span">
                              Honey with{" "}
                            </span>
                            <span className="premium-dual-header-second-header ">
                              {" "}
                              Sweet
                            </span>
                          </h2>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              className="elementor-section elementor-top-section elementor-element elementor-element-d7a13e2 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
              data-id="d7a13e2"
              data-element_type="section"
            >
              <div className="elementor-container elementor-column-gap-default" style={{display:"flex",justifyContent:"space-between" ,margin:"10px 0"}}>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7447f77"
                  data-id="7447f77"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-c3c146c elementor-widget elementor-widget-image"
                      data-id="c3c146c"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/honey-varieties/">
                          <img
                            decoding="async"
                            width={500}
                            height={400}
                            src="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-39.png"
                            className="elementor-animation-grow attachment-large size-large wp-image-5348"
                            alt=""
                            srcSet="https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-39.png 500w, https://giftofnatures.com/wp-content/uploads/2023/09/Untitled-design-39-300x240.png 300w"
                            sizes="(max-width: 500px) 100vw, 500px"
                          />{" "}
                        </a>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-024fc56 premium-header-inline elementor-widget elementor-widget-premium-addon-dual-header"
                      data-id="024fc56"
                      data-element_type="widget"
                      data-widget_type="premium-addon-dual-header.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/honey-varieties/">
                          <h2 className="premium-dual-header-first-header ">
                            <span className="premium-dual-header-first-span">Honey </span>
                            <span className="premium-dual-header-second-header ">
                              Varieties
                            </span>
                          </h2>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-0fb8b19"
                  data-id="0fb8b19"
                  data-element_type="column"
                >
                  <div className="elementor-widget-wrap elementor-element-populated">
                    <div
                      className="elementor-element elementor-element-0e99da0 elementor-widget elementor-widget-image"
                      data-id="0e99da0"
                      data-element_type="widget"
                      data-widget_type="image.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/natural-honey/">
                          <img
                            loading="lazy"
                            decoding="async"
                            width={500}
                            height={400}
                            src="https://giftofnatures.com/wp-content/uploads/2023/09/4-3.png"
                            className="elementor-animation-grow attachment-large size-large wp-image-5349"
                            alt=""
                            srcSet="https://giftofnatures.com/wp-content/uploads/2023/09/4-3.png 500w, https://giftofnatures.com/wp-content/uploads/2023/09/4-3-300x240.png 300w"
                            sizes="(max-width: 500px) 100vw, 500px"
                          />{" "}
                        </a>
                      </div>
                    </div>
                    <div
                      className="elementor-element elementor-element-6d4ea8d premium-header-inline elementor-widget elementor-widget-premium-addon-dual-header"
                      data-id="6d4ea8d"
                      data-element_type="widget"
                      data-widget_type="premium-addon-dual-header.default"
                    >
                      <div className="elementor-widget-container">
                        <a href="https://giftofnatures.com/product-category/natural-honey/">
                          <h2 className="premium-dual-header-first-header ">
                            <span className="premium-dual-header-first-span">
                              Natural{" "}
                            </span>
                            <span className="premium-dual-header-second-header ">
                              Honey
                            </span>
                          </h2>{" "}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
      
          </div>

        </div>



      </section>
    </>
  );
}

export default PhotoGallery;
